import * as Sentry from '@sentry/vue';
import { isOnline, isHidden } from '@/utils/DeviceUtil';

export class Logger {
  static info (message) {
    Sentry.withScope((scope) => {
      scope.setExtra('context', { isOnline: isOnline(), isHidden: isHidden() });
      Sentry.captureMessage(message);
    });
  }

  static exception (error) {
    if (isHidden() && error.message !== undefined && error.message !== null && typeof error.message === 'string' && error.message.includes('timeout')) {
      return false;
    }
    if (error.message !== undefined && error.message !== null && typeof error.message === 'string' && error.message.includes('status code 401')) {
      return false;
    }

    Sentry.withScope((scope) => {
      scope.setExtra('context', { isOnline: isOnline(), isHidden: isHidden() });
      Sentry.captureException(error);
    });
  };
}
