export const isOnline = () => {
  if (typeof window.navigator !== 'object' || typeof window.navigator.onLine !== 'boolean') {
    return true;
  }

  return window.navigator.onLine;
};

export const online = (callback, add = true) => {
  if (typeof callback === 'function') {
    if (add) {
      window.addEventListener('online', callback, { capture: true });
    } else {
      window.removeEventListener('online', callback, { capture: true });
    }
  }
};

export const offline = (callback, add = true) => {
  if (typeof callback === 'function') {
    if (add) {
      window.addEventListener('offline', callback, { capture: true });
    } else {
      window.removeEventListener('offline', callback, { capture: true });
    }
  }
};

export const isHidden = () => {
  if (typeof document.hidden === 'boolean') { // Opera 12.10 and Firefox 18 and later support
    return document.hidden;
  } else if (typeof document.msHidden === 'boolean') {
    return document.msHidden;
  } else if (typeof document.webkitHidden === 'boolean') {
    return document.webkitHidden;
  }

  return false;
};

export const visibility = (callback, add = true) => {
  let eventType;
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    eventType = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    eventType = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    eventType = 'webkitvisibilitychange';
  }

  if (eventType !== undefined && typeof callback === 'function') {
    if (add) {
      window.addEventListener(eventType, callback, { capture: true });
    } else {
      window.removeEventListener(eventType, callback, { capture: true });
    }
  }
};
