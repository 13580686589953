<template>
  <div id="misc">
    <alert-notification :message="alert" />
    <img class="misc-mask" height="226" :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)">

    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">{{ $t('receiving.' + status + '.title') }}</span>
      </h2>
      <p v-for="content in $t('receiving.' + status + '.content')" :key="content" class="text-sm">
        {{ content }}
      </p>
      <div v-if="loading">
        <div class="misc-character d-flex justify-center">
          <v-progress-circular :rotate="-90" :size="80" :width="10" indeterminate color="primary" dense />
        </div>
        <v-btn color="primary" class="mb-4" @click="getPaymentLink">
          {{ $t('common.button.refresh') }}
        </v-btn>
      </div>
      <div v-else-if="link">
        <v-btn color="primary" :href="link" class="mb-4">
          {{ $t('receiving.button.pay') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { mdiAlert } from '@mdi/js';
  import { Logger } from '@/services/common/Logger';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/components/notification/AlertNotification';

  export default {
    name: 'Receiving',
    components: { AlertNotification },
    setup () {
      return {
        icons: {
          mdiAlert
        }
      };
    },
    data () {
      return {
        loading: false,
        alert: '',
        orderShopifyId: undefined,
        paymentId: undefined,
        link: undefined,
        status: 'unpaid'
      };
    },
    mounted: async function () {
      this.orderShopifyId = this.$route.query.sid;
      this.paymentId = this.$route.query.po_id;
      this.status = (typeof this.paymentId === 'string' && this.paymentId.length > 0 ? 'paid' : 'unpaid');
      // if (this.status === 'unpaid') {
      //   window.onunload = function () { return confirm(''); };
      //   window.onbeforeunload = function () { return ''; };
      // }
      await this.getPaymentLink();
    },
    methods: {
      async getPaymentLink () {
        if (this.loading) {
          return;
        }

        this.loading = true;
        try {
          if (typeof this.orderShopifyId !== 'string' || this.orderShopifyId.length === 0) {
            Logger.info('invalid sid', { sid: this.orderShopifyId, url: location.href });
            throw new Error(this.$t('receiving.error.invalidOrderReference'));
          }

          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'payment/link?orderProvider=shopify&orderReference=' + this.orderShopifyId + '&t=' + Date.now());
          if (response.data !== undefined) {
            // window.onunload = undefined;
            // window.onbeforeunload = undefined;
            if (response.data.paid) {
              await this.$router.push({ name: 'confirmation', query: { sid: this.orderShopifyId } });
            } else if (typeof response.data.url === 'string' && response.data.url.length > 0) {
              this.link = response.data.url;
              location.href = this.link;
            } else {
              Logger.exception(new Error('order has been canceled'), { sid: this.orderShopifyId });
              await this.$router.push({ name: 'canceled', query: { sid: this.orderShopifyId } });
            }
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n);
        }
        this.loading = false;
      }
    }
  };
</script>

<style lang="scss">
  @import '@core/preset/preset/misc.scss';
</style>
