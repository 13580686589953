<template>
  <v-alert v-if="message" style="position:fixed;top:0;left:30%;z-index:999;" type="error" dismissible dark icon="mdi-alert">
    {{ message }}
  </v-alert>
</template>

<script>
  export default {
    name: 'AlertNotification',
    props: {
      message: {
        type: String,
        required: true
      }
    }
  };
</script>
